export function FormatDateAsTimeAgo (d: Date): string {
  const dateVal = new Date(d.toString() + 'Z')
  const seconds = Math.floor((Date.now() - dateVal.getTime()) / 1000)

  let interval = Math.floor(seconds / 31536000)
  if (interval === 1) {
    return '1 year ago'
  } else if (interval > 1) {
    return interval.toString() + ' years ago'
  }

  interval = Math.floor(seconds / 2592000)
  if (interval === 1) {
    return '1 month ago'
  } else if (interval > 1) {
    return interval.toString() + ' months ago'
  }

  interval = Math.floor(seconds / 86400)
  if (interval === 1) {
    return '1 day ago'
  } else if (interval > 1) {
    return interval.toString() + ' days ago'
  }

  interval = Math.floor(seconds / 3600)
  if (interval === 1) {
    return '1 hour ago'
  } else if (interval > 1) {
    return interval.toString() + ' hours ago'
  }

  interval = Math.floor(seconds / 60)
  if (interval === 1) {
    return '1 minute ago'
  } else if (interval > 1) {
    return interval.toString() + ' minutes ago'
  }

  if (seconds < 10) {
    return 'just now'
  }

  return Math.floor(seconds).toString() + ' seconds ago'
}

export function TranslateDate (date: Date): Date {
  return new Date(date.toString() + 'Z')
}

export function RemoveOffset (date: Date): Date {
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
}
