import { Box, CircularProgress, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { type SelectOption, TabDisplay } from '@oaisd/michdev.components.react'
import { OrganizationOverview } from './Overview'
import { OrganizationList } from './OrganizationList'
import { OrganizationUsers } from './Users'
import { OrganizationConfiguration } from './Configuration'
import { GetOrganization, UpdateStatus } from '../../../services/OrganizationService'
import { type OrganizationDetailsModel } from '../../../models/OrganizationDetailsModel'
import { OrganizationStatus, OrganizationType } from '../../../utils/genericTypes'
import { ProjectConfiguration } from './ProjectConfiguration'
import { Colors } from '../../../utils/colors'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { BreadcrumbNav } from '../../customComponents/BreadcrumbNav'
import { useAuth } from '../../../hooks/use-auth'

export class OrgTabs {
  static readonly Overview = '0'
  static readonly OrganizationList = '1'
  static readonly Users = '2'
  static readonly Configuration = '3'
}

export function OrganizationDetails (): JSX.Element {
  const { id: orgId, tab: tabString } = useParams()
  const { setCurrentUser } = useAuth()

  const tab = parseInt(tabString ?? '0')

  const [tabValue, setTabValue] = useState<number>(0)
  const [org, setOrg] = useState<OrganizationDetailsModel | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (orgId == null) {
        return
      }

      const organization = await GetOrganization(orgId ?? '')
      setOrg(organization)
      setTabValue(tab)
    }

    void fetchData()
  }, [orgId, tab])

  const handleStatusChange = async (e: any): Promise<void> => {
    if (org == null) {
      return
    }

    const status = e.target.value as OrganizationStatus
    setOrg({ ...org, status })
    await UpdateStatus(org.id.toString(), status)
  }

  const onMidxVerified = async (): Promise<void> => {
    if (org == null) {
      return
    }

    setOrg({ ...org, status: OrganizationStatus.Active })
    await setCurrentUser()
  }

  if (org == null) {
    return <CircularProgress />
  }

  let statusColor = '#726F74'
  if (org.status === OrganizationStatus.Active) {
    statusColor = Colors.ACTIVE_TEXT
  }
  if (org.status === OrganizationStatus.Inactive) {
    statusColor = Colors.INACTIVE_TEXT
  }

  const orgStatusOptions: Array<SelectOption<string>> = []
  if (org.status === OrganizationStatus.Onboarding) {
    orgStatusOptions.push({ id: OrganizationStatus.Onboarding, name: OrganizationStatus.Onboarding })
  } else {
    orgStatusOptions.push({ id: OrganizationStatus.Active, name: OrganizationStatus.Active })
  }
  orgStatusOptions.push({ id: OrganizationStatus.Inactive, name: OrganizationStatus.Inactive })

  return <Box>
    <BreadcrumbNav />

    <Typography variant='h3' className='page-header' data-testid='pageHeader'>{org.type} Details</Typography>

    <Box sx={{ display: 'flex', alignItems: 'center', mt: '.5em' }}>
      <Typography variant='h4' className='page-subheader' data-testid='subPageHeader'>{org.name}</Typography>

      {org.type !== OrganizationType.Project &&
        <Select
          value={org.status}
          sx={{ fontSize: '.875em', height: '1.75em', ml: '3em' }}
          inputProps={{ 'aria-label': 'Organization Status' }}
          data-testid='orgStatusSelect'
          onChange={handleStatusChange}
          renderValue={(value) =>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FiberManualRecordIcon sx={{ color: statusColor, fontSize: '1em', mr: '5px' }} />
              {value}
            </Box>
          }
        >
          {orgStatusOptions.map((option) => (
            <MenuItem key={option.id} value={option.id} data-testid={`statusMenuItem-${option.name}`}>{option.name}</MenuItem>
          ))}
        </Select>
      }
    </Box>

    <Tabs value={tabValue} onChange={(e, val) => { setTabValue(val) }} sx={{ mb: '2em', mt: '1em' }}>
      <Tab label='Overview' value={0} sx={{ textTransform: 'none' }} data-testid='overviewTab' />
      <Tab label='Organization List' value={1} sx={{ textTransform: 'none' }} data-testid='organizationListTab' />
      <Tab label='Users' value={2} sx={{ textTransform: 'none' }} data-testid='usersTab' />
      {org.type === OrganizationType.Project &&
        <Tab label='Configuration' value={3} sx={{ textTransform: 'none' }} data-testid='projectConfigTab' />
      }
      {/* {(org.type === OrganizationType.ISD || org.type === OrganizationType.District) &&
        <Tab label='Configuration' value={3} sx={{ textTransform: 'none' }} data-testid='configTab' />
      } */}
    </Tabs>

    <TabDisplay index={0} value={tabValue}>
      <OrganizationOverview primaryOrg={org} />
    </TabDisplay>
    <TabDisplay index={1} value={tabValue}>
      <OrganizationList primaryOrg={org} />
    </TabDisplay>
    <TabDisplay index={2} value={tabValue}>
      <OrganizationUsers primaryOrg={org} />
    </TabDisplay>
    <TabDisplay index={3} value={tabValue}>
      {org.type === OrganizationType.Project &&
        <ProjectConfiguration />
      }
      {org.type !== OrganizationType.Project &&
        <OrganizationConfiguration orgId={org.id} onMidxVerified={onMidxVerified} />
      }
    </TabDisplay>
  </Box>
}
