import { ArrowBack, CheckCircleOutline, Help } from '@mui/icons-material'
import { Box, Breadcrumbs, Card, Checkbox, CircularProgress, Grid, Link, Tooltip, Typography } from '@mui/material'
import { generatePath, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { AppRoutes } from '../../Routes'
import { WorkflowNavigation, type WorkflowStep } from '../../customComponents/WorkflowNavigation'
import { useEffect, useState } from 'react'
import { GetCETAAssessment, GetClassroomDetails, GetNameAndTeamMemberInfo, GetTemplateInstructions, SaveCETAAssessment } from '../../../services/CETAClassroomService'
import { type CETAAssessmentSection, type CETAAssessment, type CETAAssessmentIndicator } from '../../../models/CETAAssessment'
import { RatingRadio } from '../../customComponents/RatingRadio'
import { BlockButton } from '../../customComponents/buttons/BlockButton'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { TextArea } from '../../customComponents/TextArea'
import { toast } from 'react-toastify'
import { CETAOverviewTabs } from './CETAOverview'
import { CETAInstructionsKey } from '../../../models/CETATemplateInstructions'

export const CETASnapshot = (): JSX.Element => {
  const { classroomId, snapshotId } = useParams()
  const nav = useNavigate()

  const [cetaCodingSystemText, setCetaCodingSystemText] = useState<string>('')
  const [teacherName, setTeacherName] = useState<string>()
  const [activeSection, setActiveSection] = useState<CETAAssessmentSection>()
  const [initialSectionIndex, setInitialSectionIndex] = useState<number>(0)
  const [assessment, setAssessment] = useState<CETAAssessment>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [switchingSections, setSwitchingSections] = useState<number>(-1)
  const [canAccessClassroom, setCanAccessClassroom] = useState<boolean>(false)
  const readOnly = assessment?.completed ?? false

  const workflowSteps: WorkflowStep[] = assessment?.sections.map((section, index) => {
    const totalIndicators = section.indicators.length
    const completedIndicators = section.indicators.filter(indicator => indicator.rating != null).length

    return {
      id: index,
      name: `${completedIndicators}/${totalIndicators} ${section.name}`,
      skipped: section.skipped
    }
  }) ?? []
  if (!readOnly) {
    workflowSteps.push({ id: -1, name: 'Finalize & Submit' })
  }

  const fetchData = async (): Promise<void> => {
    if (classroomId == null || snapshotId == null) {
      return
    }

    const results = await Promise.all([
      GetTemplateInstructions(classroomId),
      GetCETAAssessment(snapshotId),
      GetClassroomDetails(classroomId),
      GetNameAndTeamMemberInfo(classroomId)
    ])

    const assessmentRecord = results[1]

    setCetaCodingSystemText(results[0].codingSystem)
    setAssessment(assessmentRecord)
    setTeacherName(results[2].teacherName)
    setCanAccessClassroom(results[3].teamMember != null)

    if (assessmentRecord.completed && assessmentRecord.sections.length > 0) {
      setInitialSectionIndex(0)
      setActiveSection(assessmentRecord.sections[0])
    } else {
      const latestSection = assessmentRecord.sections
        .find(section => !section.skipped && section.indicators.some(indicator => indicator.rating == null))
      setActiveSection(latestSection)

      if (latestSection != null) {
        setInitialSectionIndex(assessmentRecord.sections.indexOf(latestSection))
      } else {
        setInitialSectionIndex(assessmentRecord.sections.length)
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    void fetchData()
  }, [classroomId])

  useEffect(() => {
    if (!isSaving && switchingSections !== -1) {
      goToSection(switchingSections)
    }
  }, [isSaving])

  const goBackToDashboard = (): void => {
    const route = generatePath(AppRoutes.CETA_OVERVIEW, { classroomId: classroomId ?? '', tab: CETAOverviewTabs.Dashboard })
    nav(route)
  }

  const goToSection = (index: number): void => {
    setSwitchingSections(index)
    if (isSaving) {
      return
    }

    if (activeSection != null && !activeSection.skipped && activeSection.indicators.some(indicator => indicator.rating == null)) {
      if (!toast.isActive(1)) {
        toast.warning('There were unscored indicators. They will have to be scored before submitting.', { toastId: 1 })
      }
    }

    const section = assessment?.sections[index]
    setActiveSection(section)
    setSwitchingSections(-1)
    window.scrollTo(0, 0)
  }

  const saveSections = async (sections: CETAAssessmentSection[]): Promise<void> => {
    if (assessment == null || isSaving) {
      return
    }

    setIsSaving(true)

    const activeSectionId = activeSection?.id
    const tmpAssessment = { ...assessment, sections }
    await SaveCETAAssessment(tmpAssessment)
    setAssessment(tmpAssessment)

    const updatedSection = tmpAssessment.sections.find(section => section.id === activeSectionId)
    setActiveSection(updatedSection)

    setIsSaving(false)
  }

  const saveIndicators = async (indicators: CETAAssessmentIndicator[]): Promise<void> => {
    if (activeSection == null || assessment == null) {
      return
    }

    const updatedSections = assessment.sections.map(section => {
      if (section.id === activeSection.id) {
        return { ...section, indicators }
      }

      return section
    })

    await saveSections(updatedSections)
  }

  const skipSection = async (): Promise<void> => {
    if (activeSection == null || assessment == null) {
      return
    }

    const updatedSections = assessment.sections.map(section => {
      if (section.id === activeSection.id) {
        return { ...section, skipped: !section.skipped }
      }

      return section
    })

    await saveSections(updatedSections)
  }

  const updateRating = async (indicatorId: number, rating: number): Promise<void> => {
    if (activeSection == null || assessment == null) {
      return
    }

    const updatedIndicators = activeSection.indicators.map(indicator => {
      if (indicator.id === indicatorId) {
        return { ...indicator, rating }
      }

      return indicator
    })

    await saveIndicators(updatedIndicators)
  }

  const updateNotes = async (indicatorId: number, notes: string): Promise<void> => {
    if (activeSection == null || assessment == null) {
      return
    }

    const updatedIndicators = activeSection.indicators.map(indicator => {
      if (indicator.id === indicatorId) {
        return { ...indicator, notes }
      }

      return indicator
    })

    await saveIndicators(updatedIndicators)
  }

  const goToPreviousSection = (): void => {
    if (assessment == null) {
      return
    }

    let currentIndex = assessment.sections.length
    if (activeSection != null) {
      currentIndex = assessment.sections.indexOf(activeSection)
    }
    const previousIndex = currentIndex - 1
    if (previousIndex < 0) {
      return
    }

    goToSection(previousIndex)
    setInitialSectionIndex(previousIndex)
  }

  const goToNextSection = (): void => {
    if (assessment == null || activeSection == null) {
      return
    }

    const currentIndex = assessment.sections.indexOf(activeSection)
    const nextIndex = currentIndex + 1
    if (nextIndex >= assessment.sections.length && readOnly) {
      return
    }

    goToSection(nextIndex)
    setInitialSectionIndex(nextIndex)
  }

  let snapshotType = 'Snapshot'
  if (assessment?.isKickoff === true) {
    snapshotType = 'Kickoff'
  }

  const submitSnapshot = async (): Promise<void> => {
    if (assessment == null) {
      return
    }

    if (assessment.sections.some(section => !section.skipped && section.indicators.some(indicator => indicator.rating == null))) {
      toast.error('There are unscored indicators. Please score all indicators before submitting.')
      return
    }

    const updatedAssessment = { ...assessment, completed: true }
    await SaveCETAAssessment(updatedAssessment)
    setAssessment(updatedAssessment)

    toast.success(`CETA ${snapshotType} submitted`)
    goBackToDashboard()
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (!canAccessClassroom) {
    return <Typography>You are not authorized to view this classroom.</Typography>
  }

  return <Box>
    <Breadcrumbs maxItems={1} aria-label='breadcrumb' sx={{ mb: '.5em' }}>
      <Link sx={{ cursor: 'pointer' }} underline='hover' color='inherit' onClick={goBackToDashboard} data-testid='backToDashboard'>
        <ArrowBack sx={{ verticalAlign: 'middle', mr: '5px' }} />
        Back to CETA Dashboard
      </Link>
    </Breadcrumbs>

    <Box sx={{ px: '1.5em' }}>
      <Typography variant='h3' className='page-header'>{(teacherName != null) ? `${teacherName}'s Classroom` : ''}</Typography>
      <Typography variant='h4' className='page-subheader' data-testid='subPageHeader' mt='.5em'>CETA {snapshotType}</Typography>

      <Grid container spacing={4} mt='1em'>
        <Grid item width='25%' minWidth='330px'>
          <Typography variant='h5' sx={{ fontSize: '1em', fontWeight: 'bold', pl: '1em', mb: '.75em' }}>CETA Sections</Typography>
          <WorkflowNavigation initialStep={initialSectionIndex} steps={workflowSteps} onStepClick={goToSection} />
        </Grid>

        <Grid item width='75%'>
          <Card sx={{ py: '25px', pl: '25px', pr: '1em', borderRadius: '16px' }}>
            {activeSection != null &&
              <Grid container spacing={4}>
                <Grid item sx={{ width: '60%' }}>
                  <Box display='flex' mb='2em'>
                    <Typography variant='h5' sx={{ fontWeight: 'bold', maxWidth: '375px' }}>{activeSection.name}</Typography>
                    <Checkbox
                      checked={activeSection.skipped}
                      sx={{ p: 0, ml: '1em', color: 'var(--grey-500) !important' }}
                      disabled={readOnly}
                      onClick={skipSection}
                      inputProps={{ 'aria-label': 'Skip this Section' }}
                      data-testid='skipSection'
                    />

                    <Typography variant='body1' ml='.5em' alignContent='center'>Skip this Section</Typography>
                    <Tooltip title={'Skipping this section means you will not be recording any data for this section.'}>
                      <Help sx={{ alignSelf: 'center', color: '#59555B', width: '.7em', height: '.7em', ml: '.25em' }} />
                    </Tooltip>
                  </Box>

                  {!activeSection.skipped && activeSection.indicators.map(indicator => (
                    <Grid container key={indicator.id} data-testid={`indicator-${indicator.id}`}>
                      <Grid item>
                        <Typography variant='h6' sx={{ fontWeight: 'bold', mb: '1em' }}>{indicator.name}</Typography>
                      </Grid>
                      <Grid container item>
                        <Grid item sx={{ width: '50%' }}>
                          <Typography variant='body1'>{indicator.description}</Typography>
                        </Grid>
                        <Grid item sx={{ ml: '1em' }}>
                          <RatingRadio
                            initialValue={indicator.rating}
                            readOnly={readOnly}
                            onValueSelected={async rating => { await updateRating(indicator.id, rating) }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={{ mr: '1em' }}>
                        <TextArea
                          initialValue={indicator.notes}
                          readOnly={readOnly}
                          name='indicatorNotes'
                          placeholder='Notes'
                          onBlur={async (e) => { await updateNotes(indicator.id, e.target.value) }}
                          inputSx={{ my: '1em' }}
                        />
                      </Grid>
                    </Grid>
                  ))}

                  <Box sx={{ borderTop: '1px solid var(--border)', display: 'flex', justifyContent: 'space-between' }}>
                    {(assessment?.sections.indexOf(activeSection) ?? -1) > 0 &&
                      <BlockButton
                        onClick={goToPreviousSection}
                        dataTestId='previousSection'
                        sx={{ height: '4em', ml: '1em', mt: '1em' }}
                      >
                        Previous
                      </BlockButton>
                    }

                    {((assessment?.sections.indexOf(activeSection) ?? -1) < (assessment?.sections.length ?? 0) - 1 || !readOnly) &&
                      <BlueButton
                        onClick={goToNextSection}
                        dataTestId='nextSection'
                        sx={{ height: '4em', ml: '1em', mt: '1em' }}
                      >
                        Next
                      </BlueButton>
                    }
                  </Box>
                </Grid>

                {!activeSection.skipped &&
                  <Grid item sx={{ pl: '0px !important', ml: '1em', maxWidth: '320px' }}>
                    <Card
                      sx={{
                        backgroundColor: 'var(--off-white)',
                        minHeight: '3em',
                        width: '320px',
                        border: '1px solid var(--blue-300)',
                        p: '1em'
                      }}
                    >
                      <Typography variant='h5' color='var(--blue-700)'>
                        {CETAInstructionsKey.CETACodingSystem}
                      </Typography>

                      <div className='ql-editor' dangerouslySetInnerHTML={{ __html: cetaCodingSystemText }} data-testid='CETACodingSystem-text' />
                    </Card>
                  </Grid>
                }
              </Grid>
            }

            {activeSection == null && !readOnly &&
              <Box maxWidth='900px'>
                <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Finalize & Submit</Typography>
                <Typography variant='body1' mt='.5em'>
                  If you want to make any changes to your CETA {snapshotType}, you can click on any of the sections to the left to make adjustments and return here to finalize.
                </Typography>
                <Typography variant='body1' mt='1em'>
                  Upon clicking "Submit CETA {snapshotType}", your data will be entered into the system. Please ensure the information is accurate to your best knowledge as this CETA {snapshotType} will be locked after submission. Please know that further information can be added later in the process.
                </Typography>

                <Box sx={{ borderTop: '1px solid var(--border)', display: 'flex', justifyContent: 'space-between' }}>
                  <BlockButton
                    onClick={goToPreviousSection}
                    dataTestId='previousSection'
                    sx={{ height: '4em', ml: '1em', mt: '1em' }}
                  >
                    Previous
                  </BlockButton>

                  <BlueButton
                    onClick={submitSnapshot}
                    dataTestId='submitCETASnapshot'
                    sx={{ height: '4em', ml: '1em', mt: '1em' }}
                  >
                    Submit CETA {snapshotType}
                    <CheckCircleOutline sx={{ ml: '0.5em' }} />
                  </BlueButton>
                </Box>
              </Box>
            }
          </Card>
        </Grid>
      </Grid>
    </Box>
  </Box>
}
