// import { PersonOutlined } from '@mui/icons-material'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material'
import { IconWithLabel } from '../../customComponents/IconWithLabel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
// import AddAlertIcon from '@mui/icons-material/AddAlertOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { type UserOrganization } from '../../../models/UserOrganization'
import { useState, useRef } from 'react'
import { SchoolIcon } from '../../../assets/svgs/SchoolIcon'
import { OrganizationType } from '../../../utils/genericTypes'
import { WarningCard } from '../../customComponents/WarningCard'
import { AuthFunctions } from '../../../utils/permissions'
// import { DistrictSubscriptionOptions, ISDSubscriptionOptions, ProjectSubscriptionOptions, RCNSubscriptionOptions } from '../../../models/SubscriptionOptions'

interface Props {
  readOnly: boolean
  org: UserOrganization
  handleDefaultOrgClicked: (orgId: number) => Promise<void>
  handleEditOrg: (org: UserOrganization) => void
  handleRemoveOrg: (org: UserOrganization) => void
  handleBuildingsEdit: (org: UserOrganization) => void
  handleSubscriptionsEdit: (org: UserOrganization) => void
}

export const UserProfileAccordion = (props: Props): JSX.Element => {
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  const anchorEl = useRef<HTMLButtonElement>(null)

  const handleEditOrg = (): void => {
    props.handleEditOrg(props.org)
    setOpenMenu(false)
  }

  const handleRemoveOrg = (): void => {
    props.handleRemoveOrg(props.org)
    setOpenMenu(false)
  }

  const handleBuildingsEdit = (): void => {
    props.handleBuildingsEdit(props.org)
    setOpenMenu(false)
  }

  // Will not be part of MVP
  // const handleSubscriptionsEdit = (): void => {
  //   props.handleSubscriptionsEdit(props.org)
  //   setOpenMenu(false)
  // }

  const handleDefaultOrgClicked = async (): Promise<void> => {
    await props.handleDefaultOrgClicked(props.org.id)
    setOpenMenu(false)
  }

  // let numberOfAvailableSubsByOrgType = 0
  // if (props.org.organizationType === OrganizationType.Project) {
  //   numberOfAvailableSubsByOrgType = ProjectSubscriptionOptions.length
  // } else if (props.org.organizationType === OrganizationType.RCN) {
  //   numberOfAvailableSubsByOrgType = RCNSubscriptionOptions.length
  // } else if (props.org.organizationType === OrganizationType.ISD) {
  //   numberOfAvailableSubsByOrgType = ISDSubscriptionOptions.length
  // } else if (props.org.organizationType === OrganizationType.District) {
  //   numberOfAvailableSubsByOrgType = DistrictSubscriptionOptions.length
  // }

  const canHaveBuildings = props.org.organizationType === OrganizationType.ISD || props.org.organizationType === OrganizationType.District
  const mustHaveBuildings = canHaveBuildings && (props.org.userFunctions.includes(AuthFunctions.CETA_PARTICIPATION) ||
    props.org.userFunctions.includes(AuthFunctions.CETA_PARTICIPATION))

  return <Accordion key={props.org.id} data-testid={`orgAccordion-${props.org.id}`}>
    <AccordionSummary
      data-testid='expandOrg'
      expandIcon={<KeyboardArrowDownIcon sx={{ color: 'white', bgcolor: '#726F74', width: '1.125em', height: '1.125em', borderRadius: '50%' }} />}
    >
      {!props.org.isDefault && <IconWithLabel
        icon={<StarBorderIcon sx={{ alignSelf: 'center' }} />}
        labelText={props.org.name}
        labelSx={{ fontSize: '1.25em', mr: '1em' }}
      />}
      {props.org.isDefault && <IconWithLabel
        icon={<StarIcon sx={{ alignSelf: 'center' }} />}
        labelText={props.org.name}
        labelSx={{ fontSize: '1.25em', mr: '1em' }}
      />}
    </AccordionSummary>

    <AccordionDetails>
      {mustHaveBuildings && props.org.buildings.length === 0 &&
        <WarningCard sx={{ my: '1em' }} message='User must have a building for CETA purposes.' />
      }
      <Box sx={{ ml: '2em' }}>
        <Typography data-testid={`${props.org.roleName}-Label`}>{props.org.roleName}</Typography>
        <ul>
          {props.org.userFunctions.map((func) => (
            <li key={func}>
              {func}
            </li>
          ))}
        </ul>
      </Box>

      {canHaveBuildings &&
        <Accordion>
          <AccordionSummary data-testid='expandBuildings' expandIcon={<KeyboardArrowDownIcon />}>
            <Box sx={{ alignSelf: 'center' }}>
              <SchoolIcon />
            </Box>
            <Box sx={{ ml: '1em', alignSelf: 'center' }}>
              <Typography sx={{ fontWeight: '600' }}>{props.org.buildings.length} Building{props.org.buildings.length !== 1 ? 's' : ''}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {props.org.buildings.map((b) => (
                <Typography key={b.id} sx={{ ml: '1em' }}>{b.name}</Typography>
              ))}
              {props.org.buildings.length === 0 && <Typography sx={{ ml: '1em' }}>No buildings</Typography>}
            </Box>
          </AccordionDetails>
        </Accordion>
      }

      {/* Will not be part of MVP */}
      {/* <Accordion>
        <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
          <PersonOutlined sx={{ alignSelf: 'center' }} />
          <Box sx={{ ml: '1em' }}>
            <Typography sx={{ fontWeight: '600' }}>Subscriptions</Typography>
            <Typography>{props.org.subscriptions.length} of {numberOfAvailableSubsByOrgType} Selected</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {props.org.subscriptions.map((sub) => (
              <IconWithLabel key={sub.id} icon={<AddAlertIcon />} labelText={sub.name} sx={{ ml: '1em' }} />
            ))}
            {props.org.subscriptions.length === 0 && <Typography sx={{ ml: '1em' }}>No subscriptions</Typography>}
          </Box>
        </AccordionDetails>
      </Accordion> */}

      {!props.readOnly && <IconButton aria-label="menu" data-testid="ellipsesMenu" ref={anchorEl} onClick={() => { setOpenMenu(true) }} sx={{ float: 'right' }}>
        <MoreHorizIcon fontSize='large' sx={{ fill: 'black' }} />
      </IconButton>}

      <Menu open={openMenu} anchorEl={anchorEl.current} onClose={() => { setOpenMenu(false) }}>
        <MenuItem
          data-testid='menuItem-EditAccess'
          onClick={handleEditOrg}
        >
          Edit Access
        </MenuItem>
        <MenuItem
          data-testid='menuItem-RemoveAccess'
          onClick={handleRemoveOrg}
        >
          Remove Access
        </MenuItem>
        {canHaveBuildings &&
          <MenuItem
            data-testid='menuItem-EditBuildings'
            onClick={handleBuildingsEdit}
          >
            Edit Buildings
          </MenuItem>
        }
        {/* <MenuItem
          data-testid='menuItem-EditSubscriptions'
          onClick={handleSubscriptionsEdit}
        >
          Edit Subscriptions
        </MenuItem> */}
        {!props.org.isDefault && <MenuItem
          data-testid='menuItem-SetDefault'
          onClick={handleDefaultOrgClicked}
        >
          Set as Default
        </MenuItem>}
      </Menu>
    </AccordionDetails>
  </Accordion>
}
