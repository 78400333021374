import { Modal, TextFieldWithLabel } from '@oaisd/michdev.components.react'
import { type SyntheticEvent, useEffect, useState } from 'react'
import type { AddCETAClassroomModel } from '../../../models/AddCETAClassroomModel'
import { AccessStatus, SecurityLevel, type SelectableOption, YesNoAnswer } from '../../../utils/genericTypes'
import { Box, CircularProgress, FormControlLabel, Radio, RadioGroup, type SelectChangeEvent, Typography } from '@mui/material'
import { UserDetails } from '../../customComponents/user/Details'
import type { User } from '../../../models/User'
import { SelectWithLabel } from '../../customComponents/SelectWithLabel'
import { AddCETAClassroom, GetUserDetails, GetTeacherAndCoachOptionsForCurrentOrg } from '../../../services/CETAClassroomService'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import { useAuth } from '../../../hooks/use-auth'
import type { FieldError } from '../../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { GetAvailableBuildings } from '../../../services/OrganizationService'
import { BannerModal } from '../../customComponents/BannerModal'

interface Props {
  timeSpanId: number
  closeModal: () => void
  handleAddClassroom: () => void
}

export interface AddCETAClassroomDDLOptions {
  coaches: SelectableOption[]
  teachers: SelectableOption[]
}

const newTeacherOption = { id: 0, name: 'Add New Teacher' }
const emptyUser: User = {
  id: 0,
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  updatedAt: new Date(),
  updatedBy: '',
  accessStatus: AccessStatus.Active,
  isActive: true,
  organizations: [],
  buildings: [],
  securityLevel: SecurityLevel.DistrictUser
}

export const AddCETAClassroomModal = (props: Props): JSX.Element => {
  const { user } = useAuth()

  const [isLoading, setIsLoading] = useState(true)
  const [teacherOptions, setTeacherOptions] = useState<SelectableOption[]>([])
  const [selectedTeacher, setSelectedTeacher] = useState<User>(emptyUser)
  const [buildingOptions, setBuildingOptions] = useState<SelectableOption[]>([])
  const [selectedBuilding, setSelectedBuilding] = useState<number>()
  const [selectedPurposeAnswer, setSelectedPurpose] = useState('')
  const [selectedRCNAnswer, setSelectedRCNAnswer] = useState<SelectableOption>({ id: 2, name: YesNoAnswer.Unsure })
  const [selectedCoachAnswer, setSelectedCoachAnswer] = useState<SelectableOption>({ id: 2, name: YesNoAnswer.Unsure })
  const [coachOptions, setCoachOptions] = useState<SelectableOption[]>([])
  const [selectedCoach, setSelectedCoach] = useState<SelectableOption>()
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([])
  const [userIsACoach, setUserIsACoach] = useState(false)
  const [duplicateUserId, setDuplicateUserId] = useState<number | null>(null)

  const rcnContract: string = '1. Is this CETA being completed as part of the RCN Contract Activities?'
  const purpose: string = '2. What is your purpose for completing the CETA? *'
  const coachExists: string = '3. Is there a START-trained coach on this team?'
  const coachSelectLabel: string = '4. Who is the coach for this classroom?'
  const purposeOption1: string = 'I am a teacher using the CETA as a self-reflection tool'
  const purposeOption2: string = 'I am a member of a team focused on supporting the classroom'
  const purposeOption3: string = 'I am a member of a team focused on classroom practices to support a specific student'
  const yesNoOptions: SelectableOption[] = [{ id: 0, name: YesNoAnswer.Yes }, { id: 1, name: YesNoAnswer.No }, { id: 2, name: YesNoAnswer.Unsure }]

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const ddlOptions: AddCETAClassroomDDLOptions = await GetTeacherAndCoachOptionsForCurrentOrg()

      const options = [newTeacherOption, ...ddlOptions.teachers]
      setTeacherOptions(options)

      const buildings = await GetAvailableBuildings()
      setBuildingOptions(buildings)

      if (buildings.length === 1) {
        setSelectedBuilding(buildings[0].id)
      }

      const teacherUser = ddlOptions.teachers.find((o) => o.id === (user?.id ?? 0))

      if (teacherUser != null) {
        await changeTeacher(teacherUser.id.toString())
      }

      const coachUser = ddlOptions.coaches.find((o) => o.id === (user?.id ?? 0))
      setUserIsACoach(coachUser !== undefined)
      if (coachUser !== undefined && teacherUser === undefined) {
        setSelectedCoachAnswer({ id: 0, name: YesNoAnswer.Yes })
        setSelectedCoach(coachUser)
      }

      setCoachOptions(ddlOptions.coaches)
      setIsLoading(false)
    }

    void fetchData()
  }, [])

  const onTeacherChanged = async (e: SyntheticEvent, value: { id: string | number, name: string } | null): Promise<void> => {
    if (value == null || value.id === 0) {
      setSelectedTeacher(emptyUser)
      return
    }

    await changeTeacher(value.id as string)
  }

  const changeTeacher = async (teacherValue: string): Promise<void> => {
    const selectedTeach = await GetUserDetails(teacherValue)
    setSelectedTeacher(selectedTeach)
  }

  const onCoachChanged = async (e: SyntheticEvent, value: { id: string | number, name: string } | null): Promise<void> => {
    if (value !== null) {
      setSelectedCoach({ id: value.id as number, name: value.name })
    } else {
      setSelectedCoach(undefined)
    }
  }

  const handleRCNSelect = (e: SelectChangeEvent<number>): void => {
    const rcnId = e.target.value
    setSelectedRCNAnswer(yesNoOptions.find((opt) => opt.id.toString() === rcnId) ?? yesNoOptions[2])
  }

  const handleCoachSelect = (e: SelectChangeEvent<number>): void => {
    const coachId = e.target.value
    const answer: SelectableOption | undefined = yesNoOptions.find((opt) => opt.id.toString() === coachId)
    setSelectedCoachAnswer(answer ?? yesNoOptions[2])
    if (answer?.name !== YesNoAnswer.Yes) {
      setSelectedCoach(undefined)
    }
  }

  const handleBuildingSelect = (e: SelectChangeEvent<number>): void => {
    const buildingId = e.target.value as number
    setSelectedBuilding(buildingId)
  }

  const newClassroomRequest = (): AddCETAClassroomModel => {
    return {
      cetaPurpose: selectedPurposeAnswer,
      hasStartCoach: selectedCoachAnswer.name,
      isRCNContract: selectedRCNAnswer.name,
      teacherId: duplicateUserId ?? selectedTeacher?.id,
      teacherFirstName: selectedTeacher?.firstName,
      teacherLastName: selectedTeacher?.lastName,
      teacherEmail: selectedTeacher?.email,
      buildingId: selectedBuilding ?? 0,
      coachId: selectedCoach === undefined ? null : selectedCoach.id,
      timeSpanId: props.timeSpanId
    }
  }

  const handleSubmit = async (): Promise<void> => {
    const request = newClassroomRequest()
    const validation = await AddCETAClassroom(request)

    if (validation.errors.length > 0) {
      validation.errors.forEach((error) => {
        toast.error(error)
      })
      setFieldErrors(validation.fieldErrors)
      return
    }

    if ((validation.insertedId ?? 0) > 0) {
      setDuplicateUserId(validation.insertedId ?? 0)
      return
    }
    toast.success('Classroom added successfully.')
    props.handleAddClassroom()
  }

  const handleTeacherFieldUpdate = (e: SyntheticEvent): void => {
    const target = e.target as HTMLInputElement
    setSelectedTeacher({ ...selectedTeacher, [target.name]: target.value })
  }

  return <Modal
    title='CETA Classroom'
    open={true}
    maxWidth='lg'
    onClose={props.closeModal}
    onConfirm={handleSubmit}
    noButtons={false}
    buttonClassName='modal-button'
    confirmButtonClassName='modal-confirm-button'
    confirmButtonText='Save Changes'
    confirmationContent={
      isLoading
        ? <CircularProgress />
        : <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginBottom: '.5em', width: '40%' }}>
            <MUIAutoComplete
              label='Teacher'
              name='teacherSelect'
              disabled={!userIsACoach}
              value={selectedTeacher != null ? { id: selectedTeacher.id, name: `${selectedTeacher.firstName} ${selectedTeacher.lastName}` } : undefined }
              onChange={onTeacherChanged}
              required={true}
              showRequiredText={fieldErrors.some((error) => error.fieldName === 'teacherSelect')}
              options={teacherOptions}
              sx={{ width: '100%', marginBottom: '1em', marginTop: '.5em' }}
            />

            {selectedTeacher.id === 0 && userIsACoach && <>
              <TextFieldWithLabel
                name='firstName'
                label='First Name'
                value={selectedTeacher.firstName}
                required={fieldErrors.some((error) => error.fieldName === 'firstName')}
                showRequiredText={fieldErrors.some((error) => error.fieldName === 'firstName')}
                error={fieldErrors.some((error) => error.fieldName === 'firstName')}
                dataTestId='firstName'
                textFieldClassName='pb-0'
                onChange={handleTeacherFieldUpdate}
              />
              <TextFieldWithLabel
                name='lastName'
                label='Last Name'
                value={selectedTeacher.lastName}
                required={fieldErrors.some((error) => error.fieldName === 'lastName')}
                showRequiredText={fieldErrors.some((error) => error.fieldName === 'lastName')}
                error={fieldErrors.some((error) => error.fieldName === 'lastName')}
                dataTestId='lastName'
                textFieldClassName='pb-0'
                onChange={handleTeacherFieldUpdate}
              />
              <TextFieldWithLabel
                name='email'
                label='Email'
                value={selectedTeacher.email}
                required={fieldErrors.some((error) => error.fieldName === 'email')}
                showRequiredText={fieldErrors.some((error) => error.fieldName === 'email')}
                error={fieldErrors.some((error) => error.fieldName === 'email')}
                dataTestId='email'
                textFieldClassName='pb-0'
                onChange={handleTeacherFieldUpdate}
              />
            </>}

            {selectedTeacher.id > 0 &&
              <UserDetails
                maxWidth='100%'
                imageKey={selectedTeacher?.imageKey}
                detailName={selectedTeacher?.firstName ?? ''}
                isOrganizationView={true}
                infoDisplay={'Teacher Information'}
                users={selectedTeacher === undefined ? [] : [selectedTeacher]}
                isEditable={false}
                onUpdate={() => {}}
              />
            }
          </Box>

          <Box sx={{ margin: '1em', width: '60%' }}>
            <SelectWithLabel
              name='rcnContractSelect'
              labelContent={<Typography sx={{ fontWeight: 'bold', marginBottom: '1em', marginTop: '1em' }} variant='body2'>{rcnContract}</Typography>}
              value={selectedRCNAnswer.id}
              options={yesNoOptions}
              width='40%'
              onChange={handleRCNSelect}
            />

            <Typography sx={{ fontWeight: 'bold', marginBottom: '1em', marginTop: '1em' }} variant='body2'>{purpose}</Typography>

            <RadioGroup onChange={(e, value) => { setSelectedPurpose(value) }} value={selectedPurposeAnswer}>
              {fieldErrors.some((error) => error.fieldName === 'cetaPurpose') && <Box sx={{ fontWeight: 400, color: 'var(-error)' }}>Required</Box>}

              <FormControlLabel data-testid='purposeoption1' value={purposeOption1} control={<Radio />} label={purposeOption1} />
              <FormControlLabel data-testid='purposeoption2' value={purposeOption2} control={<Radio />} label={purposeOption2} />
              <FormControlLabel data-testid='purposeoption3' value={purposeOption3} control={<Radio />} label={purposeOption3} />
            </RadioGroup>

            <SelectWithLabel
              name='coachExistsSelect'
              labelContent={ <Typography sx={{ fontWeight: 'bold', marginBottom: '1em', marginTop: '1em' }} variant='body2'>{coachExists}</Typography> }
              value={selectedCoachAnswer.id}
              options={yesNoOptions}
              width='40%'
              onChange={handleCoachSelect}
            />

            {selectedCoachAnswer.name === YesNoAnswer.Yes &&
              <MUIAutoComplete
                separateLabel={true}
                labelContent={<Typography sx={{ fontWeight: 'bold', marginBottom: '1em', marginTop: '1em' }} variant='body2'>{coachSelectLabel}</Typography>}
                name='coachSelect'
                value={selectedCoach != null ? { id: selectedCoach.id, name: selectedCoach.name } : undefined }
                onChange={onCoachChanged}
                options={coachOptions}
                sx={{ width: '100%' }}
              />
            }

            <SelectWithLabel
              name='buildings'
              labelContent={ <Typography sx={{ fontWeight: 'bold', my: '1em' }} variant='body2'>Select Building</Typography> }
              required={fieldErrors.some((error) => error.fieldName === 'buildings')}
              showRequiredText={fieldErrors.some((error) => error.fieldName === 'buildings')}
              error={fieldErrors.some((error) => error.fieldName === 'buildings')}
              value={selectedBuilding ?? ''}
              disabled={buildingOptions.length === 1}
              options={buildingOptions}
              width='40%'
              onChange={handleBuildingSelect}
            />
          </Box>

          {duplicateUserId != null &&
            <BannerModal
              title='User Already Exists'
              cancelButtonText='No, Cancel'
              confirmButtonText='Yes, Continue'
              dataTestId='duplicateUserModal'
              bodyContent={<Typography>This user already exists. Continuing will add the user to the district and building. Are you sure you want to continue?</Typography>}
              onClose={() => { setDuplicateUserId(null) }}
              onConfirm={handleSubmit}
            />
          }
        </Box>
    }
  />
}
