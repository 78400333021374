import { Box, Button, Card, CircularProgress, Container, Typography } from '@mui/material'
import { useAuth } from '../hooks/use-auth'
import miLaunchpadLogo from '../assets/milaunchpad.png'
import { BlueButton } from './customComponents/buttons/BlueButton'
import { AuthFunctions, hasPermission } from '../utils/permissions'
import { AppRoutes } from './Routes'
import { generatePath, useNavigate } from 'react-router'
import { PAGE_MARGIN_TOP, PAGE_MARGIN_X } from './masterComponents/layout'
import { toast } from 'react-toastify'
import { StartHome } from '../assets/svgs/StartHome'

export function HomePage (): JSX.Element {
  const auth = useAuth()
  const nav = useNavigate()

  const goToNextStep = (): void => {
    if (auth.user == null) {
      return
    }

    if (hasPermission(auth.user, AuthFunctions.CETA_PARTICIPATION) && !auth.user.isOrgOnboarding) {
      nav(AppRoutes.IMPLEMENTATION)
    } else if (hasPermission(auth.user, AuthFunctions.PROJECT_SETTINGS) ||
               hasPermission(auth.user, AuthFunctions.RCN_MANAGEMENT) ||
               hasPermission(auth.user, AuthFunctions.ISD_MANAGEMENT) ||
               hasPermission(auth.user, AuthFunctions.DISTRICT_MANAGEMENT) ||
               hasPermission(auth.user, AuthFunctions.RCN_ADMINISTRATION) ||
               hasPermission(auth.user, AuthFunctions.ISD_ADMINISTRATION) ||
               hasPermission(auth.user, AuthFunctions.DISTRICT_ADMINISTRATION)) {
      const adminRoute = generatePath(AppRoutes.ADMINISTRATION, { id: auth.user.currentOrgId.toString() })
      nav(adminRoute)
    } else {
      toast.error('You currently do not have access to the system. Please contact your administrator.')
    }
  }

  if (auth.loading) {
    return <CircularProgress />
  }

  return (<Box>
    <StartHome
      style={{
        position: 'relative',
        width: '100vw',
        height: 'fit-content',
        left: `-${PAGE_MARGIN_X / 2}vw`,
        top: `-${PAGE_MARGIN_TOP}vh`,
        marginLeft: `-${PAGE_MARGIN_X / 2}vw`,
        marginRight: `-${PAGE_MARGIN_X / 2}vw`
      }}
    />

    {auth.user == null && <Box>
      <Typography variant='h3' className='page-header'> Login </Typography>
      <Box sx={{ display: 'flex', mt: '1.25em' }}>
        <Card>
          <Container>
            <Typography variant='h6' component='h1'>
              MiCredentials
            </Typography>

            <Typography>
              Use your MiCredentials login information to log in to START.
            </Typography>

            <Button
              variant='contained'
              color='primary'
              onClick={auth.miCredsLogin}
              sx={{ mt: '1.25em', mb: '.625em', textTransform: 'none' }}
            >
              Login
            </Button>
          </Container>
        </Card>

        <Card sx={{ ml: '1.25em' }}>
          <Container>
            <Typography variant='h6' component='h1'>
              MiLaunchPad
            </Typography>

            <Typography>
              Use your local district login information to log in to START.
            </Typography>

            <Box>
              <img alt={'Powered by MiLaunchPad'} src={miLaunchpadLogo} />
            </Box>

            <Button
              variant='contained'
              color='primary'
              onClick={auth.miLaunchPadLogin}
              sx={{ mt: '1.25em', mb: '.625em', textTransform: 'none' }}
            >
              Login
            </Button>
          </Container>
        </Card>
      </Box>
    </Box>}

    {auth.user != null && <Card sx={{ p: '1em' }}>
      <Typography variant='h4' fontWeight='600' mb='.5em'> Welcome, {auth.user.firstName} {auth.user.lastName} </Typography>

      {auth.user.isActive && !auth.user.noAccount &&
        <Box sx={{ display: 'flex' }}>
          {/* START Planning and Data System Tutorial Video */}
          <Box>
            <iframe
              data-testid="tutorialVideo"
              width="600px"
              height="300px"
              src="https://www.youtube.com/embed/gHrObYpgBtw"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen={true}
            />
          </Box>

          <Box sx={{ ml: '1em', justifyContent: 'center' }}>
            <Typography sx={{ mt: '1.25em' }}>
              Welcome to the START Planning and Data System (PDS)! The START PDS offers school staff a collection of tools to promote the use of evidence-based practices for students with autism and related disabilities. Check out the video tutorial for tips on how to navigate the START PDS.
            </Typography>

            <BlueButton onClick={goToNextStep} sx={{ float: 'right', mt: '1em' }} dataTestId='nextStep'>
              Take Your Next Step
            </BlueButton>
          </Box>
        </Box>
      }

      {(auth.user.noAccount || !auth.user.isActive) &&
        <Box>
          <Typography sx={{ color: 'red', mt: '1.25em' }}>
            You have successfully logged in, but your account is not authorized for START.
          </Typography>

          <Button
            variant='contained'
            color='primary'
            onClick={auth.logout}
            sx={{ mt: '1.25em', textTransform: 'none' }}
          >
            Logout
          </Button>
        </Box>
      }
    </Card>}
  </Box>)
}
